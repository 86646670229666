<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >
        <CForm id="award_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <div style="text-align: justify;">Estás a punto de adquirir uno de los productos de la categoría:</div>
                <br />
                <div style="text-align: center;">
                  <div v-if="item.type == 'event_points'" style="font-size: 14px; font-weight: bold; color: #28E;">Eventos y Conciertos</div>
                  <div v-if="item.type == 'card_points'" style="font-size: 14px; font-weight: bold; color: #28E;">Certificados Digitales</div>
                  <div v-if="item.type == 'gift_points'" style="font-size: 14px; font-weight: bold; color: #28E;">Premios en Especie</div>
                  <div v-if="item.type == 'wallet_points'" style="font-size: 14px; font-weight: bold; color: #28E;">Dépositos en Monedero</div>
                </div>
                <br />
                <div style="text-align: center;"><img :src="item.image_filename ? item.image_filename : 'no-image.jpg'" style="height: 120px;"></div>
                <br />
                <div style="text-align: center; font-size: 14px; font-weight: bold;">{{item.name}}</div>
                <br />
                <div style="text-align: center; font-size: 12px; font-style: italic;">{{item.description}}</div>
                <br />
                <div style="text-align: center; font-size: 14px;">Precio en Puntos: {{item.price}}</div>
                <br />
                <div style="text-align: center; font-size: 14px;">Disponibles: {{item.stock}}</div>
                <br />
                <div style="text-align: justify;">Si deseas continuar con el <b>canje de puntos</b>, se te descontarán <span style="font-weight: bold;">{{item.price}} puntos</span> de tu cuenta y en los próximos días te contactaremos para entregarte el premio seleccionado.</div>
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="boughtProduct" :color="color">Confirmar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric, minLength, helpers, requiredIf, requiredUnless } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone';

import ws from '../../../services/challenges';
import store from '../../../store'

export default {
  name: 'AwardBuyModal',
  props: {

  },
  components: { 'v-date-picker': DatePicker },
  data () {
    return {
      showModal: false,
      title: "Nuevo Premio",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [
        {label: 'Puntos para Eventos', value: 'event_points'},
        {label: 'Puntos para Certificados', value: 'card_points'},
        {label: 'Puntos para Premios', value: 'gift_points'},
        {label: 'Puntos para Monedero', value: 'wallet_points'}
      ],
      link: '',
      item: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal (product) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Canjear Puntos';
      this.isEdit = false;
      this.submitted = false;

      this.item = product;

      this.form = {
          id: product.id,
          type: product.type,
          price: product.price
      }
    },
    boughtProduct () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("bought", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          type: 'event_points',
          price: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {

    }
  },
}
</script>