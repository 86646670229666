<template>
  <div>
    <CRow>
      <CCol md="12">
        <!-- <div style="float: right; text-align: right;"><h1 style="font-size: 1.5rem">Puntos: {{ points }}</h1></div> -->
        <span style="float: right;"><img src="login.jpg" style="width: 100px; border-radius: 10px;"></span>
        <h1 style="font-size: 1.6rem"><b>KPI BONUS</b></h1>
        <br />
        <h1 style="font-size: 1.0rem">¡Hola {{user_name.split(' ')[0]}}!</h1>
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" style="text-align: center">
        <CButton @click="changeTab('challenges')" :color="tab == 'challenges' ? 'info' : 'default'" size="sm" style="margin: 5px; font-size: 12px;">Concursos y Retos</CButton>
        <CButton @click="changeTab('awards')" :color="tab == 'awards' || tab == 'points' || tab == 'shops' ? 'info' : 'default'" size="sm" style="margin: 5px; font-size: 12px;">Recompensas</CButton>
        <CButton @click="changeTab('account')" :color="tab == 'account' ? 'info' : 'default'" size="sm" style="margin: 5px; font-size: 12px;">Mi cuenta</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <br />
      </CCol>
    </CRow>
    <CRow v-if="tab == 'challenges'">
      <CCol md="12" v-for="(challenge, index) in challenges" :key="index" style="font-size: 12px;">
      <a :href="'/#/challenge/'+challenge.id+'/view'">
        <CCard>
          <CCardHeader>
            {{challenge.name}}
            <div class="card-header-actions">
              <span>...</span>
            </div>
          </CCardHeader>
          <CCardBody>
            <a :href="'/#/challenge/'+challenge.id+'/view'">
              <img v-if="!challenge.image_filename" src="no-image.jpg" style="width: 100%">
              <img v-if="challenge.image_filename" :src="challenge.image_filename" style="width: 100%;">
            </a>
          </CCardBody>
          <CCardFooter>
            <p style="text-align: justify;  font-size: 12px;">{{challenge.description}}</p>
            <div style="float: right; font-size: 11px;" v-if="challenge.enabled && challenge.quantity > 0">Premios Disponibles: {{challenge.left}}</div>
          </CCardFooter>
        </CCard>
        </a>
      </CCol>
    </CRow>
    <CRow v-if="tab == 'awards'">
      <CCol md="12" style="font-size: 12px;">
        <div style="font-size: 14px; font-weight: bold;">Puntos Obtenidos</div>
        <hr />
        <table>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-calendar" size="3xl" style="color: #28E;" /></td>
            <td>
              Puntos para Eventos y Conciertos
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changePointsTab('event')">Ver Detalles</a></td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changeShopTab('event')">Canjear</a></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">{{points.event_points | number}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-credit-card" size="3xl" style="color: #28E;" /></td>
            <td>
              Puntos para Certificados Digitales
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changePointsTab('card')">Ver Detalles</a></td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changeShopTab('card')">Canjear</a></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">{{points.card_points | number}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-briefcase" size="3xl" style="color: #28E;" /></td>
            <td>
              Puntos para Premios en Especie
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changePointsTab('gift')">Ver Detalles</a></td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changeShopTab('gift')">Canjear</a></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">{{points.gift_points | number}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-money" size="3xl" style="color: #28E;" /></td>
            <td>
              Puntos para Depósitos en Monederos
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changePointsTab('wallet')">Ver Detalles</a></td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"><a @click="changeShopTab('wallet')">Canjear</a></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">{{points.wallet_points | number}}</td>
          </tr>
          <!--
          <tr>
            <td colspan="3">
              <hr />
              <br />
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div style="font-size: 14px; font-weight: bold;">Premios Recibidos</div>
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-calendar" size="3xl" style="color: #28E;" /></td>
            <td>
              Eventos y Conciertos
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;">Ver Historial</td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">0</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-credit-card" size="3xl" style="color: #28E;" /></td>
            <td>
              Certificados Digitales
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;">Ver Historial</td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">0</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-briefcase" size="3xl" style="color: #28E;" /></td>
            <td>
              Premios en Especie
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;">Ver Historial</td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">0</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="width: 65px; text-align: center; vertical-align: top; top: 0px; padding: 0px;"><CIcon name="cil-money" size="3xl" style="color: #28E;" /></td>
            <td>
              Depósitos en Monederos
              <br />
              <br />
              <table>
                <tr>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;">Ver Historial</td>
                  <td style="width: 100px; text-align: left; font-weight: bold; color: #28E;"></td>
                </tr>
              </table>
            </td>
            <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">0</td>
          </tr>
          -->
          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
        </table>
      </CCol>
    </CRow>
    <CRow v-if="tab == 'points'">
      <CCol md="12" style="font-size: 12px;">
        <CButton @click="changeTab('awards')" style="float: right;" color="dark" size="sm">Volver</CButton>
        <div v-if="points_type == 'event'" style="font-size: 14px; font-weight: bold; color: #28E;">Puntos para Eventos y Conciertos</div>
        <div v-if="points_type == 'card'" style="font-size: 14px; font-weight: bold; color: #28E;">Puntos para Certificados Digitales</div>
        <div v-if="points_type == 'gift'" style="font-size: 14px; font-weight: bold; color: #28E;">Puntos para Premios en Especie</div>
        <div v-if="points_type == 'wallet'" style="font-size: 14px; font-weight: bold; color: #28E;">Puntos para Dépositos en Monedero</div>
        <hr />
        <table style="width: 100%;" v-if="point_tickets.length > 0">
          <template v-for="ticket in point_tickets">
            <tr>
              <td>
                {{ticket.date | date}}<br />
                {{ticket.description}}
              </td>
              <td style="width: 80px; text-align: right; font-weight: bold;font-size: 18px; color: #28E;">{{ticket.points | number}}</td>
            </tr>
            <tr>
              <td colspan="2">
                <hr />
              </td>
            </tr>
          </template>
        </table>
        <div v-else style="text-align: center; width: 100%">PARTICIPA EN LOS RETOS Y CONCURSOS</div>
      </CCol>
    </CRow>
    <CRow v-if="tab == 'shops'">
      <CCol md="12" style="font-size: 12px;">
        <span style="float: right; font-size: 18px; font-weight: bold; color: #28E;">{{points[shop_type+"_points"] | number}}</span>
        <div v-if="shop_type == 'event'" style="font-size: 14px; font-weight: bold; color: #28E;">Eventos y Conciertos</div>
        <div v-if="shop_type == 'card'" style="font-size: 14px; font-weight: bold; color: #28E;">Certificados Digitales</div>
        <div v-if="shop_type == 'gift'" style="font-size: 14px; font-weight: bold; color: #28E;">Premios en Especie</div>
        <div v-if="shop_type == 'wallet'" style="font-size: 14px; font-weight: bold; color: #28E;">Dépositos en Monedero</div>
        <hr />
        <table style="width: 100%;" v-if="point_products.length > 0">
          <template v-for="(product, index) in point_products">
            <tr>
              <td style="width: 33%; text-align: center; height: 120px; padding: 5px;">
                <div style="border: 1px solid; padding: 20px;">
                  <span v-if="product.city" style='float: right; font-size: 12px; font-style: italic; font-weight: bold;'>{{product.city}}</span>
                  <br v-if="product.city" />
                  <br v-if="product.city" />
                  <img :src="product.image_filename ? product.image_filename : 'no-image.jpg'" style="height: 120px;">
                  <br /><br />
                  {{product.description}}
                  <br /><br />
                  <b>{{product.price | number}} Puntos</b>
                  <span v-if="product.stock > 0 && points[shop_type+'_points'] >= product.price && (product.is_multiple == 1 || (product.is_multiple == 0 && product.is_bought == 0))">
                    <br /><br />
                    <CButton @click="getProduct(product)" :color="'success'" size="sm" style="margin: 5px; font-size: 12px;">COMPRAR</CButton>
                  </span>
                  <span v-else>
                    <span v-if="product.stock == 0" style="color: #28E;">
                      <br /><br /><b>NO DISPONIBLE</b>
                    </span>
                    <span v-else style="color: #28E;">
                      <span v-if="product.is_bought" style="color: #28E;">
                        <br /><br /><b>ESTE PREMIO YA HA SIDO CANJEADO</b>
                      </span>
                      <span v-else style="color: #28E;">
                        <span v-if="points[shop_type+'_points'] < product.price" style="color: #28E;">
                          <br /><br /><b>NO TIENES PUNTOS SUFICIENTES</b>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </table>
        <div v-else style="text-align: center; width: 100%">NO HAY PREMIOS DISPONIBLES PARA ESTA CATEGORÍA</div>
        <hr />
      </CCol>
    </CRow>

    <AwardBuyModal ref="awardBuyModal" @bought="boughtProcess"></AwardBuyModal>
  </div>
</template>
<script>

import store from '../../../store'
import challenges_ws from '../../../services/challenges';
import account_ws from '../../../services/account';
import customer_ws from '../../../services/customers';
import awards_ws from '../../../services/awards';

import TopCustomersTable from '../../../components/dashboard/TopCustomersTable.vue'
import DetalleCustomersTable from '../../../components/dashboard/DetalleCustomersTable.vue'
import ProfitCustomersTable from '../../../components/dashboard/ProfitCustomersTable.vue'

import AwardBuyModal from './AwardBuyModal.vue'


export default {
  name: 'SuppliersHome',
  components: {
    TopCustomersTable,
    DetalleCustomersTable,
    ProfitCustomersTable,
    AwardBuyModal
  },
  data () {
    return {
      tab: 'challenges',
      points_type: 'event',
      shop_type: 'event',
      name: '',
      user_name: '',
      route_name: '',
      top_customers: [],
      detalle_customers: [],
      profits_customers: [],
      total: 0,
      points: {},
      point_tickets: [],
      point_products: [],
      challenges: false
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.name = response.data.name;
      this.route_name = response.data.user.first_name;
      this.user_name = response.data.user.last_name;
    }

    let response2 = await customer_ws.getBySupplier(response.data.user.id); 

    if(response2.type == "success"){
      this.top_customers = response2.data.top;
      this.detalle_customers = response2.data.detalle;
      this.profits_customers = response2.data.profits;
      this.total = response2.data.total;
    }

    let response3 = await account_ws.getPoints(); 

    if(response3.type == "success"){
      this.points = response3.data;
    }

    let response4 = await challenges_ws.getActives(); 

    if(response4.type == "success"){
      this.challenges = response4.data;
    }
  },
  methods: {
    changeTab (tab) {
      this.tab = tab;
    },
    async changePointsTab (type) {
      this.loading();

      this.point_tickets = [];
      
      this.tab = "points";
      this.points_type = type;
      

      let response = await account_ws.getPointTickets(type); 

      if(response.type == "success"){
        this.point_tickets = response.data;

        this.loaded();
      }
    },
    async changeShopTab (type) {
      this.tab = "shops";
      this.shop_type = type;

      let response = await account_ws.getPointProducts(type); 

      if(response.type == "success"){
        this.point_products = response.data;

        this.loaded();
      }
    },
    getProduct (product) {
      this.$refs.awardBuyModal.storeModal(product);
    },
    async boughtProcess (data) {
      let response = await awards_ws.bought(data);
      
      if(response.type == "success"){
        let response2 = await account_ws.getPoints(); 

        if(response2.type == "success"){
          this.points = response2.data;
        }

        let response3 = await account_ws.getPointProducts(data.type.replaceAll("_points","")); 

        if(response3.type == "success"){
          this.point_products = response3.data;

          this.loaded();
        }
      }

      this.showToast(response.type, response.message);
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style>

  a{
    text-decoration: none;
    color: black;
  }

  a:hover{
    text-decoration: none;
    color: black;
  }

</style>
